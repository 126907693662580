import gql from "graphql-tag";
import { recurringOrderFragment } from "../order/fragments";
import { projectFragment } from "./fragments";
import { userFragment } from "@/graphql/user/fragments";

export const GET_ACTIVE_PROJECTS = gql`
  query getActiveProjects($offset: Int!, $limit: Int, $active: Boolean) {
    projects(offset: $offset, limit: $limit, active: $active) {
      hasMore
      items {
        id
        name
        description
        defaultPaymentOption
        color
        totalBilledNet
        performedAmount
        budget {
          id
          amount
          approvedAt
          themes {
            amount
            invoicedAmount
            theme {
              id
              name
            }
          }
        }
        active
        clientActions {
          ... on InvoicePaymentAction {
            date
          }
          ... on OrderApprobationAction {
            date
          }
          ... on BudgetApprobationAction {
            date
          }
        }
        themes {
          id
        }
      }
    }
  }
`;

export const GET_PROJECTS_FOR_COMPANY = gql`
  query getProjectsForCompany($companyId: ID!) {
    company(id: $companyId) {
      id
      client {
        id
        projects {
          ...ProjectFragment
        }
      }
    }
  }
  ${projectFragment}
`;

export const GET_FULL_PROJECT = gql`
  query getFullProject($id: ID!) {
    project(id: $id) {
      ...ProjectFragment
      totalBilled
      totalBilledNet
      totalNotBilledOrdered
      totalPaid
      totalPaidNet
      totalApprovedOrderedAmount
      totalOrderedAmount
      performedAmount
      client {
        id
        name
        companies {
          id
          name
        }
      }
      projectRoles {
        id
        name
        hourlyRate
      }
      nextOrderNumber
      nextRecurringOrderNumber
      previouslyApprovedBudget {
        id
        amount
      }
      internal
      needsBudgetApproval
    }
  }
  ${projectFragment}
`;

export const GET_PROJECT_CONSUMPTION_BY_THEME = gql`
  query getProjectConsumptionByTheme($id: ID!) {
    project(id: $id) {
      id
      themes {
        id
        name
        performedAmount
      }
    }
  }
`;

export const GET_PROJECT_FOR_ACCESS_RIGHTS = gql`
  query getProjectForAccessRights($id: ID!) {
    project(id: $id) {
      id
      client {
        id
      }
      active
    }
  }
`;

export const GET_PROJECT_WITH_BUDGET_AND_THEMES = gql`
  query getProjectWithBudgetAndThemes($id: ID!) {
    project(id: $id) {
      ...ProjectFragment
      budget {
        ...BudgetFragment
        status
        themes {
          theme {
            id
            performedAmount
          }
          invoicedAmount
          amount
        }
      }
      previouslyApprovedBudget {
        id
        amount
      }
      themes {
        id
        name
        externalIds
      }
      internal
    }
  }
  ${projectFragment}
`;

export const GET_BASIC_PROJECT = gql`
  query getBasicProject($id: ID!) {
    project(id: $id) {
      id
      name
      description
      active
      internal
      defaultPaymentOption
      needsBudgetApproval
      architectureImage {
        name
        url
      }
    }
  }
`;

export const GET_PROJECT_INVOICES_MENU = gql`
  query getProjectInvoicesMenu($id: ID!) {
    project(id: $id) {
      ...ProjectFragment
      internal
      client {
        id
        name
        companies {
          id
          name
        }
      }
      projectRoles {
        id
        name
        hourlyRate
      }
      nextOrderNumber
      nextRecurringOrderNumber
      invoicesMenu {
        menuDate
        menuTotal
        menuBalance
        menuOrdered
      }
    }
  }
  ${projectFragment}
`;

export const GET_PROJECT_RECURRING_ORDERS = gql`
  query getProjectRecurringOrders($id: ID!) {
    project(id: $id) {
      id
      client {
        id
      }
      recurringOrders {
        ...RecurringOrderFragment
      }
    }
  }
  ${recurringOrderFragment}
`;

export const GET_EMPLOYEE_PROJECTS = gql`
  query employeeProjects($offset: Int!, $limit: Int) {
    employeeProjects(offset: $offset, limit: $limit) {
      hasMore
      items {
        id
        name
      }
    }
  }
`;

export const GET_EMPLOYEE_MONTHLY_PROJECTS = gql`
  query monthlyProjects {
    myMonthlyProjects {
      id
      name
      color
      workingPeriod {
        performedAmount
        orderedAmount
        startDate
        endDate
      }
    }
  }
`;

export const GET_EMPLOYEE_PROJECT = gql`
  query project($id: ID!) {
    project(id: $id) {
      id
      name
      color
      totalBilledNet
      performedAmount
      budget {
        id
        amount
        approvedAt
      }
      previouslyApprovedBudget {
        id
        amount
      }
      totalOrderedAmount
      workingPeriod {
        performedAmount
        orderedAmount
        invoicedAmount {
          gross
          net
          paid
        }
        startDate
        endDate
      }
    }
  }
`;

export const GET_EMPLOYEE_ROLES = gql`
  query employeeRoles($projectId: ID!) {
    employeeRoles(projectId: $projectId) {
      id
      name
    }
  }
`;

export const GET_PROJECTS_DASHBOARD = gql`
  query getProjects($offset: Int!, $limit: Int) {
    projectsWithWarningFirsts(offset: $offset, limit: $limit) {
      hasMore
      items {
        ...ProjectFragment
        totalBilledNet
        totalNotBilledOrdered
        budgetWarning
        client {
          id
          name
        }
        lateApprovalOrdersCount
        latePaymentInvoicesCount
        latePaymentBalance
        latePaymentDays
      }
    }
  }

  ${projectFragment}
`;

export const GET_PROJECT_DASHBOARD_URL = gql`
  query getProject($id: ID!, $month: Date) {
    project(id: $id) {
      id
      name
      dashboardUrl(month: $month)
    }
  }
`;

export const GET_LOGGED_HOURS = gql`
  query getLoggedHours(
    $projectId: ID!
    $startDate: Date!
    $userId: ID
    $endDate: Date
  ) {
    loggedHours(
      projectId: $projectId
      startDate: $startDate
      userId: $userId
      endDate: $endDate
    ) {
      user {
        id
        firstName
        lastName
      }
      plannedAmount(includingPast: false)
      loggedHours
      plannedHours
    }
  }
`;

export const GET_PROJECT_PLANNING = gql`
  query getProjectPlanning(
    $projectId: ID!
    $startDate: Date!
    $endDate: Date!
  ) {
    project(id: $projectId) {
      id
      color
      workingPeriod(startDate: $startDate, endDate: $endDate) {
        holidays {
          id
          name
          date
        }
        plannedTimes {
          id
          user {
            id
            firstName
            lastName
            workDayHours
          }
          startDate
          endDate
          frequency
          repeatEndDate
        }
        timeOffs {
          id
          startDate
          endDate
          user {
            id
            firstName
            lastName
            workDayHours
          }
          repeatEndDate
          frequency
        }
      }
    }
  }
`;

export const GET_PROJECT_ORDERS = gql`
  query GetProjectOrders($offset: Int!, $projectId: ID!, $limit: Int) {
    project(id: $projectId) {
      id
      name
      # description
      defaultPaymentOption
      active
      client {
        id
        name
        companies {
          id
          name
        }
      }
      projectRoles {
        id
        name
        hourlyRate
      }
      nextOrderNumber
      nextRecurringOrderNumber
      orders(offset: $offset, limit: $limit) {
        items {
          id
          title
          startDate
          endDate
          dueDate
          status
          price
          paymentOption
          invoiced
          company {
            id
            name
          }
        }
      }
      recurringOrders {
        id
        title
        startDate
        endDate
        status
        price
        paymentOption
        company {
          id
          name
        }
      }
    }
  }
`;

export const GET_FINANCIAL_REPORT_BY_MONTH = gql`
  query ($projectId: ID!, $month: Date) {
    project(id: $projectId) {
      id
      workingPeriod(month: $month) {
        orders {
          id
          title
          price
          status
        }
        performedAmount
        invoices {
          id
          total
          netTotal
          invoiceNumber
          balance
        }
      }
    }
  }
`;

export const GET_PROJECT_CLIENT_WAITING_ACTIONS = gql`
  query getProjectClientWaitingActions($projectId: ID!) {
    project(id: $projectId) {
      id
      clientActions {
        ... on BudgetApprobationAction {
          budget {
            id
            amount
          }
        }
        ... on InvoicePaymentAction {
          invoice {
            id
            invoiceNumber
            balance
            bic
            iban
            communication
          }
        }
        ... on OrderApprobationAction {
          order {
            ... on Order {
              id
              number
              price
            }
            ... on RecurringOrder {
              id
              number
              price
            }
          }
        }
      }
    }
  }
`;

export const ATTACH_ARCHITECTURE_PICTURE = gql`
  mutation attachArchitecturePicture($projectId: ID!, $fileId: ID!) {
    attachArchitectureImageToProject(projectId: $projectId, fileId: $fileId) {
      error
      status
      data {
        id
        architectureImage {
          id
          name
          url
        }
      }
    }
  }
`;

export const GET_PROJECT_USERS = gql`
  query getProjectUsers($projectId: ID!) {
    project(id: $projectId) {
      id
      accessRights(limit: 1000, offset: 0) {
        #hard-coded pagination, should be enough for a while
        items {
          user {
            id
            ...UserFragment
          }
        }
      }
    }
  }

  ${userFragment}
`;
