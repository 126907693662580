import { shadeColor } from "@/helpers/utils";

const colors = [
  "#5363F5",
  "#DADFF7",
  "#FEAC20",
  "#3A3042",
  "#00C614",
  "#2603FF",
];

export const themeColor = (index: number) => {
  const nbColorRotation = Math.floor(index / colors.length);
  console.log({ nbColorRotation });
  return shadeColor(
    colors[index % colors.length],
    Math.ceil(nbColorRotation / 2) * 20 * (nbColorRotation % 2 === 0 ? -1 : 1),
  );
};
