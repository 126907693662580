
import { computed, defineComponent, PropType, ref, watchEffect } from "vue";
import { useI18n } from "vue-i18n";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { useMutation } from "@vue/apollo-composable";
import { logErrorMessages } from "@vue/apollo-util";
import { useToast } from "vue-toastification";

import { apolloClient } from "@/apollo.provider";

import enUK from "@/locales/project/en-UK.json";

import {
  CREATE_ORDER,
  CREATE_ORDER_LINE,
  CREATE_RECURRING_ORDER,
  SEND_ORDER,
  SEND_RECURRING_ORDER,
} from "@/graphql/order/mutations";

import FormItem from "@/components/form/FormItem.vue";
import Modal from "@/components/Modal.vue";
import OrderLine from "@/components/order/OrderLine.vue";
import BasicToggle from "@/components/BasicToggle.vue";
import BasicButton from "@/components/BasicButton.vue";

import useInitNewOrder from "@/composables/useInitNewOrder";

import {
  ProjectRecurringOrdersResponse,
  ProjectRole,
} from "@/helpers/project/typesDefinition";
import { endOfMonthDate, startOfMonthDate } from "@/helpers/dates";
import {
  updateOrdersCache,
  updateRecurringOrdersCache,
} from "@/helpers/order/updateCache";
import { Project } from "@/helpers/project/typesDefinition";
import { insertNewEntity } from "@/helpers/cache/insertNewEntity";
import {
  legalNotices,
  orderDefaultTitle,
  paymentOptions,
  transformUnitIfQuantityNotInteger,
} from "@/helpers/order/utils";
import { formatPrice } from "@/helpers/utils";
import { ApolloCache, FetchResult } from "@apollo/client";
import {
  CreateOrderResponse,
  CreateRecurringOrderResponse,
  OrdersDeltasAndInvoicesResponse,
} from "@/helpers/order/typesDefinition";
import dayjs from "dayjs";

type MessageSchema = typeof enUK;

export default defineComponent({
  props: {
    project: {
      type: Object as PropType<Project>,
      required: true,
    },
    defaultIsRecurringOrder: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const { t } = useI18n<[MessageSchema], "en-UK">({
      useScope: "global",
      inheritLocale: true,
      messages: {
        "en-UK": enUK,
      },
    });

    const toast = useToast();

    const isRecurringOrder = ref(props.defaultIsRecurringOrder);
    const createOrderLinesLoading = ref(false);
    const showDetailsOnInvoice = ref(true);
    const multiMonth = ref(false);

    const { order, months, years } = useInitNewOrder(
      props.project,
      isRecurringOrder.value,
      isRecurringOrder.value
        ? t("labels.recurringOrder.recurringOrder")
        : t("labels.order.order"),
      isRecurringOrder.value
        ? props.project.nextRecurringOrderNumber
        : props.project.nextOrderNumber,
    );

    const subtotals = ref<number[]>(
      Array(props.project.projectRoles.length).fill(0),
    );
    const quantities = ref<number[]>(
      Array(props.project.projectRoles.length).fill(0),
    );
    const units = ref<number[]>(
      Array(props.project.projectRoles.length).fill(480),
    );
    const orderlineV$ = ref(Array(props.project.projectRoles.length));

    const validationRules = computed(() => ({
      title: {
        required,
      },
      startYear: {
        required,
      },
      startMonth: {
        required,
      },
      endYear: {
        required,
      },
      endMonth: {
        required,
      },
      notes: {},
      legalNotice: {},
      paymentOption: {
        required,
      },
      companyId: {},
    }));

    const v$ = useVuelidate(validationRules, order, {
      $autoDirty: true,
    });

    const totalPrice = ref(0);

    watchEffect(() => {
      totalPrice.value = subtotals.value.reduce(
        (partial_sum: number, subtotal: number) =>
          partial_sum + Math.round(subtotal),
        0,
      );
      order.legalNotice = legalNotices[order.paymentOption];
    });

    const orderId = ref("");

    const updateCacheForSingleOrder = (
      cache: ApolloCache<OrdersDeltasAndInvoicesResponse>,
      {
        data,
      }: Omit<FetchResult<{ createOrder: CreateOrderResponse }>, "context">,
    ) => {
      const createOrderResponse = data?.createOrder;
      if (createOrderResponse?.status === "ok") {
        updateOrdersCache(
          cache,
          props.project.id,
          startOfMonthDate(order.startYear, order.startMonth),
          createOrderResponse.data,
          insertNewEntity,
          1,
        );
      }
    };

    const updateCacheForRecurringOrder = (
      cache: ApolloCache<ProjectRecurringOrdersResponse>,
      {
        data,
      }: Omit<
        FetchResult<{ createRecurringOrder: CreateRecurringOrderResponse }>,
        "context"
      >,
    ) => {
      const createRecurringOrderResponse = data?.createRecurringOrder;
      if (createRecurringOrderResponse?.status === "ok") {
        updateRecurringOrdersCache(
          cache,
          props.project.id,
          createRecurringOrderResponse.data,
          insertNewEntity,
        );
      }
    };

    const formattedEndDate = (): string => {
      if (isRecurringOrder.value) {
        return endOfMonthDate(order.endYear, order.endMonth);
      } else {
        const [lastMonth, lastYear] = multiMonth.value
          ? [order.endMonth, order.endYear]
          : [order.startMonth, order.startYear];
        const endDate = dayjs({
          year: lastYear,
          month: lastMonth - 1,
        }).add(1, "month");
        return startOfMonthDate(endDate.year(), endDate.month() + 1);
      }
    };

    const creationVariables = () => ({
      projectId: props.project.id,
      startDate: startOfMonthDate(order.startYear, order.startMonth),
      endDate: formattedEndDate(),
      title: order.title,
      notes: order.notes,
      legalNotice: order.legalNotice,
      price: totalPrice.value,
      paymentOption: order.paymentOption,
      companyId: order.companyId,
      showDetailsOnInvoice: showDetailsOnInvoice.value,
    });

    const {
      mutate: createOrder,
      onDone: onDoneCreateOrder,
      onError: onErrorCreateOrder,
      loading: loadingCreateOrder,
    } = useMutation(CREATE_ORDER, () => ({
      variables: creationVariables(),
      update: updateCacheForSingleOrder,
    }));

    onDoneCreateOrder(async (result) => {
      if (result.data?.createOrder?.status === "ok") {
        orderId.value = result.data.createOrder.data?.id;
        createOrderLinesLoading.value = true;
        toast.success(t("success.order.add"));
        await Promise.all(
          props.project.projectRoles.map(
            async (projectRole: ProjectRole, index: number) => {
              return createOrderLine(index, projectRole, orderId.value);
            },
          ),
        );
        createOrderLinesLoading.value = false;
        context.emit("close");
      } else {
        const errorResponse = result.data?.createOrder?.error;
        console.log(errorResponse);
        toast.error(t("errors.order.add", { message: errorResponse }));
      }
    });

    onErrorCreateOrder((error) => {
      logErrorMessages(error);
      toast.error(t("errors.order.add", { message: t("errors.internal") }));
    });

    const {
      mutate: createRecurringOrder,
      onDone: onDoneCreateRecurringOrder,
      onError: onErrorCreateRecurringOrder,
      loading: loadingCreateRecurringOrder,
    } = useMutation(CREATE_RECURRING_ORDER, () => ({
      variables: creationVariables(),
      update: updateCacheForRecurringOrder,
    }));

    onDoneCreateRecurringOrder(async (result) => {
      if (result.data?.createRecurringOrder?.status === "ok") {
        orderId.value = result.data.createRecurringOrder.data?.id;
        createOrderLinesLoading.value = true;
        toast.success(t("success.recurringOrder.add"));
        await Promise.all(
          props.project.projectRoles.map(
            async (projectRole: ProjectRole, index: number) => {
              return createOrderLine(
                index,
                projectRole,
                undefined,
                orderId.value,
              );
            },
          ),
        );
        createOrderLinesLoading.value = false;
        context.emit("close");
      } else {
        const errorResponse = result.data?.createRecurringOrder?.error;
        console.log(errorResponse);
        toast.error(t("errors.recurringOrder.add", { message: errorResponse }));
      }
    });

    onErrorCreateRecurringOrder((error) => {
      logErrorMessages(error);
      toast.error(
        t("errors.recurringOrder.add", { message: t("errors.internal") }),
      );
    });

    async function createOrderLine(
      index: number,
      projectRole: ProjectRole,
      orderId?: string,
      recurringOrderId?: string,
    ): Promise<boolean> {
      if (quantities.value[index] === 0) return true;
      const { quantity, unit } = transformUnitIfQuantityNotInteger(
        quantities.value[index],
        units.value[index],
      );
      try {
        const result = await apolloClient.mutate({
          mutation: CREATE_ORDER_LINE,
          variables: {
            orderId,
            recurringOrderId,
            projectRoleName: projectRole.name,
            hourlyRate: projectRole.hourlyRate,
            quantity,
            unit,
          },
        });
        if (result.data?.createOrderLine?.status === "ok") {
          return true;
        } else {
          const errorResponse = result.data?.createOrderLine?.error;
          console.log(errorResponse);
          toast.error(
            t("errors.order.addLine", {
              rojectRoleName: projectRole.name,
              message: errorResponse,
            }),
          );
        }
        return false;
      } catch (error) {
        console.log(error);
        toast.error(
          t("errors.order.addLine", { message: t("errors.internal") }),
        );
        return false;
      }
    }

    const {
      mutate: sendOrder,
      onDone: onDoneSendOrder,
      onError: onErrorSendOrder,
      loading: loadingSendOrder,
    } = useMutation(SEND_ORDER, () => ({
      variables: {
        id: orderId.value,
      },
    }));

    onDoneSendOrder(async (result) => {
      if (result.data?.sendOrder?.status === "ok") {
        toast.success(t("success.order.send"));
        context.emit("close");
      } else {
        const errorResponse = result.data?.sendOrder?.error;
        console.log(errorResponse);
        toast.error(t("errors.order.send", { message: errorResponse }));
      }
    });

    onErrorSendOrder((error) => {
      logErrorMessages(error);
      toast.error(
        t("errors.order.send", { message: "Error with graphql query" }),
      );
    });

    const {
      mutate: sendRecurringOrder,
      onDone: onDoneSendRecurringOrder,
      onError: onErrorSendRecurringOrder,
      loading: loadingSendRecurringOrder,
    } = useMutation(SEND_RECURRING_ORDER, () => ({
      variables: {
        id: orderId.value,
      },
    }));

    onDoneSendRecurringOrder(async (result) => {
      if (result.data?.sendRecurringOrder?.status === "ok") {
        toast.success(t("success.recurringOrder.send"));
        context.emit("close");
      } else {
        const errorResponse = result.data?.sendRecurringOrder?.error;
        console.log(errorResponse);
        toast.error(
          t("errors.recurringOrder.send", { message: errorResponse }),
        );
      }
    });

    onErrorSendRecurringOrder((error) => {
      logErrorMessages(error);
      toast.error(
        t("errors.recurringOrder.send", {
          message: "Error with graphql query",
        }),
      );
    });

    const loading =
      loadingCreateOrder.value ||
      createOrderLinesLoading.value ||
      loadingSendOrder.value ||
      loadingSendRecurringOrder.value ||
      loadingCreateRecurringOrder.value;

    return {
      loading,
      years,
      months,
      order,
      paymentOptions,
      subtotals,
      v$,
      orderlineV$,
      createOrder,
      createRecurringOrder,
      sendOrder,
      sendRecurringOrder,
      quantities,
      units,
      totalPrice,
      formatPrice,
      toast,
      isRecurringOrder,
      t,
      multiMonth,
      showDetailsOnInvoice,
    };
  },
  watch: {
    project(updatedProject: Project) {
      this.order.title = orderDefaultTitle(
        updatedProject,
        this.t("labels.order.order"),
        this.isRecurringOrder
          ? this.project.nextRecurringOrderNumber
          : this.project.nextOrderNumber,
      );
    },
    isRecurringOrder(newValue: boolean) {
      this.order.title = orderDefaultTitle(
        this.project,
        newValue
          ? this.t("labels.recurringOrder.recurringOrder")
          : this.t("labels.order.order"),
        newValue
          ? this.project.nextRecurringOrderNumber
          : this.project.nextOrderNumber,
      );
    },
  },
  emits: ["close"],
  methods: {
    async submitForm(shouldCloseModal?: boolean): Promise<boolean> {
      const isFormCorrect = await this.v$.$validate();
      const areOrderLineFromsCorrect = await Promise.all(
        this.orderlineV$.map(async (v$) => v$.$validate()),
      );
      const positiveQuantity = this.quantities.find((quantity) => quantity > 0);
      if (!positiveQuantity) {
        this.toast.warning(this.t("warnings.order.empty"));
        return false;
      }
      if (isFormCorrect && !areOrderLineFromsCorrect.includes(false)) {
        if (this.isRecurringOrder) await this.createRecurringOrder();
        else await this.createOrder();
        if (shouldCloseModal) this.$emit("close");
        return true;
      }
      return false;
    },
    async createAndSend() {
      const formSubmitted = await this.submitForm(false);
      if (formSubmitted) {
        if (this.isRecurringOrder) await this.sendRecurringOrder();
        else await this.sendOrder();
        this.$emit("close");
      }
    },
    closeModal(): void {
      this.$emit("close");
    },
  },
  components: {
    Modal,
    FormItem,
    OrderLine,
    BasicToggle,
    BasicButton,
  },
});
